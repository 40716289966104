/*
 * @Description: 工具方法
 * @Author: zhang zhen
 * @Date: 2023-02-10 16:16:37
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-08-27 15:46:09
 * @FilePath: /page-sass/src/util/utils.js
 */
import dayjs from 'dayjs'

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  // return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
  return ''
}

/**
 * 根据组件名获取父级
 * @param vm
 * @param name
 * @returns {Vue | null|null|Vue}
 */
export function getVmParentByName(vm, name) {
  let parent = vm.$parent
  if (parent && parent.$options) {
    if (parent.$options.name === name) {
      return parent
    } else {
      let res = getVmParentByName(parent, name)
      if (res) {
        return res
      }
    }
  }
  return null
}
/**
 * @description: 生成最新的Key
 * @param {*} routes
 * @param {*} filterKeys
 * @return {*}
 */
export const createNewRoutes = (routes, filterKeys) => {
  let newRoutes = []
  for (let i = 0; i < routes.length; i++) {
    filterRoutesByUserIdentity(routes[i], filterKeys)
    newRoutes.push(routes[i])
  }
  return newRoutes
}

/**
 * @description: 生成权限路由
 * @return {Object} route 原始路由
 * @return {array} new routes 新路由
 */
export function filterRoutesByUserIdentity(route, keys) {
  const { children, name } = route
  // console.log(name, keys, keys.includes(name))
  route.visible = !keys.includes(name)
  if (children && children.length) {
    children.map(i => {
      filterRoutesByUserIdentity(i, keys)
    })
  }
}

/**
 * @description: 动态计算出面包屑
 * @param {Array} - routes 账号权限下的路由
 * @param {Array} - keys 当前路由所有的子路由
 * @param {String} - basicPath 基础路由的路径
 * @return {*}
 */
export const createBreadcrumb = (routes, keys, basicPath) => {
  let resultRoute = [];
  // 1. 初始的路由位置
  let basicRoute = routes.find(i => i.name == keys[0])
  // 找不到初始的数组就直接导出
  if (!basicRoute) return []
  const { path, meta, name, children } = basicRoute
  resultRoute.push({
    path, meta, name
  })
  // 2. 只有一级的时候
  if (keys.length == 1) {
    return resultRoute
  } else { // 大于一级
    if (!children || !children.length) {
      // 判断下是不是和第一级别同级别存在该path
      let targetRouter = routes.find(i => i.name == keys[1])
      if (targetRouter) {
        const { path, meta, name } = targetRouter
        resultRoute.push({
          path, meta, name
        })
        return resultRoute
      } else {
        return resultRoute
      }
    }
    // 3. 第二级
    let secondaryRoute = children.find(i => i.name == keys[1])
    // 没找到目标路由的时候
    if (!secondaryRoute) {
      return resultRoute
    }
    const { path, meta, name } = secondaryRoute
    // 找到了存放二级
    resultRoute.push({
      path, meta, name
    })
    if (!secondaryRoute.children) {
      return resultRoute
    }
    // 4. 处理第三级
    if (keys[2]) {
      // 第三级路由对象
      let thirdRoute = secondaryRoute.children.find(i => i.name == keys[2])
      if (!thirdRoute) {
        return resultRoute
      }
      const { path, name, meta } = thirdRoute
      resultRoute.push({
        path, name, meta
      })
      return resultRoute
    }
  }
}

/**
 * @description: 生成高亮的查询结果
 * @param {string} val - 当前词组
 * @param {string} keyword - 关键词
 * @return {*}
 */
export const brightenKeyword = (val, keyword) => {
  const Reg = new RegExp(keyword, "i");
  let res = "";
  if (val) {
    res = val.replace(
      Reg,
      `<span style="font-weight: 400;color: #FF6E2D;">${keyword}</span>`
    );
    return res;
  }
};

/**
 * @description: 生成随机的数据
 * @param {*} startDate
 * @param {*} endDate
 * @return {*}
 */
export const generateRandomData = (startDate, endDate) => {
  const dates = [startDate];
  let currentDate = dayjs(startDate);
  let nextDate;

  while (true) {
    nextDate = currentDate.add(1, 'day');
    if (nextDate.isAfter(endDate)) {
      break;
    }
    dates.push(nextDate.format('YYYY-MM-DD'));
    currentDate = nextDate;
  }

  const data = dates.map((date, index) => {
    return {
      date: date, // 格式化为YYYY-MM-DD  
      value1: Math.floor(Math.random() * 701) + 10, // 随机整数在10～800之间  
      value2: Math.floor(Math.random() * 701) + 10, // 随机整数在10～800之间  
    };
  });

  return data;
};

/**
 * 获取指定范围的日期时间范围。
 * @param {string} scope - 日期范围的类型。不同的值代表不同的时间范围：
 *                         '0' 当天
 *                         '1' 过去6天
 *                         '2' 过去29天
 *                         '3' 过去89天
 *                         '4' 过去半年
 *                         '5' 过去一年
 *                         '6' 去年全年
 * @returns {Array<string>} 返回一个包含开始和结束日期时间的字符串数组，格式为 YYYY-MM-DD HH:mm:ss。
 * @throws {Error} 如果传入的scope值无效，抛出错误。
 */
export function getDateRange(scope) {
  const now = new Date(); // 当前日期和时间  
  const start = new Date(now);
  const end = new Date(now);
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  // const hours = now.getHours();  
  // const minutes = now.getMinutes();  
  // const seconds = now.getSeconds();  

  // 设定开始和结束时间的时分秒部分  
  start.setHours(0, 0, 0, 0); // 开始时间设为当天的00:00:00  
  end.setHours(23, 59, 59, 999); // 结束时间设为当天的23:59:59.999  

  // 根据scope调整开始时间  
  switch (scope) {
    case '0':
      // 当天，无需调整  
      break;
    case '1':
      // 往前推6天  
      start.setDate(day - 6);
      break;
    case '2':
      // 往前推29天  
      start.setDate(day - 29);
      break;
    case '3':
      // 往前推89天  
      start.setDate(day - 89);
      break;
    case '4':
      // 往前推半年  
      start.setMonth(month - 6);
      if (start.getMonth() < 0) {
        start.setFullYear(year - 1);
        start.setMonth(12 + start.getMonth());
      }
      break;
    case '5':
      // 往前推一年  
      start.setFullYear(year - 1);
      break;
    case '6':
      // 去年的1月1号  
      start.setFullYear(year - 1);
      start.setMonth(0);
      start.setDate(1);
      break;
    default:
      throw new Error('Invalid scope value');
  }

  // 格式化日期为 YYYY-MM-DD HH:mm:ss 格式  
  const formatDate = (date) => {
    const padZero = (num) => num.toString().padStart(2, '0');
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
  };

  // 返回格式化后的开始和结束时间  
  return [formatDate(start), formatDate(end)];
}


export function getMonthDateRange(scope) {
  const now = new Date(); // 当前日期和时间  
  const start = new Date(now);
  const end = new Date(now);

  // 根据scope调整开始时间  
  switch (scope) {
    case '0':
      // 本月  
      start.setDate(1); // 设置为本月第一天  
      break;
    case '1':
      // 下月  
      start.setMonth(start.getMonth() + 1, 1); // 设置为下月第一天  
      break;
    case '2':
      // 两个月后  
      start.setMonth(start.getMonth() + 2, 1); // 设置为两个月后第一天  
      break;
    default:
      throw new Error('Invalid scope value');
  }

  // 设置结束时间为下月第一天的零点，即本月最后一天的最后一刻  
  end.setMonth(start.getMonth() + 1, 0); // 设置为下一个月的第0天，即本月的最后一天  
  end.setHours(23, 59, 59, 999); // 设置时间为当天的最后一刻  

  // 格式化日期为 YYYY-MM-DD HH:mm:ss 格式  
  const formatDate = (date) => {
    const padZero = (num) => num.toString().padStart(2, '0');
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
  };

  // 返回格式化后的开始和结束时间  
  return [formatDate(start), formatDate(end)];
}

/**
 * 格式化接收时间函数
 * @param {string | number | Date} receivedTime - 接收的时间，可以是日期字符串、时间戳或Date对象
 * @returns {string} 格式化后的接收时间字符串。根据时间与当前时间的比较，返回不同的格式：
 *                   如果是当天的时间，只返回小时和分钟，如"14:30";
 *                   如果是前一天的时间，返回"昨天 加上小时和分钟"，如"昨天 14:30";
 *                   如果是今年的时间但不是当天或前一天，返回"月份-日期 加上小时和分钟"，如"05-20 14:30";
 *                   如果是去年或更早的时间，返回"年份-月份-日期 加上小时和分钟"，如"2021-05-20 14:30"。
 */
export function formatReceivedTime(receivedTime) {
  const now = new Date();
  const receivedDate = new Date(receivedTime);

  // 获取接收时间的年份、月份、日期、小时和分钟  
  const receivedYear = receivedDate.getFullYear();
  const receivedMonth = String(receivedDate.getMonth() + 1).padStart(2, '0');
  const receivedDateStr = String(receivedDate.getDate()).padStart(2, '0');
  const receivedHours = String(receivedDate.getHours()).padStart(2, '0');
  const receivedMinutes = String(receivedDate.getMinutes()).padStart(2, '0');

  // 根据不同的情况返回不同的格式  
  if (now.getDate() === receivedDate.getDate() && now.getMonth() === receivedDate.getMonth() && now.getFullYear() === receivedDate.getFullYear()) {
    // 如果是当天的时间  
    return `${receivedHours}:${receivedMinutes}`;
  } else if (now.getDate() - 1 === receivedDate.getDate() && now.getMonth() === receivedDate.getMonth() && now.getFullYear() === receivedDate.getFullYear()) {
    // 如果是前一天的时间  
    return `昨天 ${receivedHours}:${receivedMinutes}`;
  } else if (now.getFullYear() === receivedYear) {
    // 如果是今年的时间  
    return `${receivedMonth}-${receivedDateStr} ${receivedHours}:${receivedMinutes}`;
  } else {
    // 如果是去年的时间或者更早  
    return `${receivedYear}-${receivedMonth}-${receivedDateStr} ${receivedHours}:${receivedMinutes}`;
  }
}

export function getDateNewRange(scope) {
  const now = new Date(); // 当前日期和时间  
  const start = new Date(now);
  const end = new Date(now);
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  // const hours = now.getHours();  
  // const minutes = now.getMinutes();  
  // const seconds = now.getSeconds();  

  // 设定开始和结束时间的时分秒部分  
  start.setHours(0, 0, 0, 0); // 开始时间设为当天的00:00:00  
  end.setHours(23, 59, 59, 999); // 结束时间设为当天的23:59:59.999  

  // 根据scope调整开始时间  
  switch (scope) {
    case '0':
      // 本月  
      start.setDate(1); // 设置为本月第一天  
      end.setMonth(start.getMonth() + 1, 0); 
      break;
    case '1':
      // 往前推6天  
      start.setDate(day - 6);
      break;
    case '3':
      // 往前推89天  
      start.setDate(day - 89);
      break;
    case '4':
      // 往前推半年  
      start.setMonth(month - 6);
      if (start.getMonth() < 0) {
        start.setFullYear(year - 1);
        start.setMonth(12 + start.getMonth());
      }
      break;
    default:
      throw new Error('Invalid scope value');
  }

  // 格式化日期为 YYYY-MM-DD HH:mm:ss 格式  
  const formatDate = (date) => {
    const padZero = (num) => num.toString().padStart(2, '0');
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
  };

  // 返回格式化后的开始和结束时间  
  return [formatDate(start), formatDate(end)];
}


export function getDateQuoteRange(scope) {
  console.log(scope)
  const now = new Date(); // 当前日期和时间  
  const start = new Date(now);
  const end = new Date(now);
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  // const hours = now.getHours();  
  // const minutes = now.getMinutes();  
  // const seconds = now.getSeconds();  

  // 设定开始和结束时间的时分秒部分  
  start.setHours(0, 0, 0, 0); // 开始时间设为当天的00:00:00  
  end.setHours(23, 59, 59, 999); // 结束时间设为当天的23:59:59.999  

  // 根据scope调整开始时间  
  switch (scope) {
    case '1':
      // 本月  
      start.setDate(1); // 设置为本月第一天  
      end.setMonth(start.getMonth() + 1, 0); 
      break;
    case '2':
      // 下个月
      start.setMonth(month + 1);
      start.setDate(1); // 设置为本月第一天 
      end.setMonth(start.getMonth() + 1, 0); 
      break;
    case '3':
      // 两个月后
      end.setMonth(month + 2);
      break;
    default:
      throw new Error('Invalid scope value');
  }

  // 格式化日期为 YYYY-MM-DD HH:mm:ss 格式  
  const formatDate = (date) => {
    const padZero = (num) => num.toString().padStart(2, '0');
    return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;
  };

  // 返回格式化后的开始和结束时间  
  return [formatDate(start), formatDate(end)];
}